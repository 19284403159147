import React, { useState } from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography/Typography";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid/Grid";
import SocialLinks from "../Column/SocialLinks";
import * as ReactGA from "react-ga";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',

            position: 'relative',
            marginTop: -310,
            /* negative value of footer height */
            height: 310,
            clear: 'both',
            ['@media (max-width:700px)']: {
                marginTop: -500,
                height: 500,
            }
        },
        wrap: {
            marginTop: 20,
            height: 310,
            backgroundColor: '#303a80',
            width: '100%',
            ['@media (max-width:700px)']: {
                height: 500,
            }

        },
        content: {
            margin: 'auto',
            width: '99%',
            paddingTop: 30,
            ['@media (max-width:700px)']: {
                width: '95%',
            }
        },
        menuItem: {
            fontSize: '.9rem',
            color: '#fff',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
            ['@media (max-width:700px)']: {
                fontSize: '0.7rem',
            }
        },
        copyright: {
            marginTop: 20,
        },
        links: {
            lineHeight: '1!important',
            color: '#fff',
            fontSize: '0.8rem!important',
            '& a':{
                fontSize: '0.8rem',
            }
        },
        mainGrid: {
            width: '100%'
        },
        left: {
            width: 300,
        },
        social: {
            marginTop: 10,
        },
        other: {
            width: 'calc(100% - 300px)',
            ['@media (max-width:700px)']: {
                width: '100%',
            }
        },
        grid: {
            width: '100%',
            '& li':{
                listStyle: 'none',
                color: '#fff',
                marginTop: 10
            },
            '& a':{
                color: '#fff',
                textDecoration: 'none',
                fontSize: '.9rem',
                '&:hover':{
                    textDecoration: 'underline',
                }
            },
            '& p':{
                fontSize: '.9rem',
            },
            '@media print' : {
                display: 'none !important'
            }
        },
        companyLinks: {
            maxWidth: '300px!important'
        },
        socialMobile: {
            marginBottom: 20
        }
    }});

export default function Footer(props) {

    const classes = useStyles();

    const year = (new Date()).getFullYear();

    const footerRef = React.createRef();

    const [maxWidth, setMaxWidth] = React.useState(1000);

    const handleFacebookClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'social_facebook_click',
            label: window.location.pathname
        });
        setTimeout(() => {
            window.location = 'https://www.facebook.com/dashpreowned';
        }, 1000);
    };

    const handleTwitterClick = () => {
        console.log('click')
        ReactGA.event({
            category: 'User',
            action: 'social_twitter_click',
            label: window.location.pathname
        });
        setTimeout(() => {
            window.location = 'https://twitter.com/dashcarsales';
        }, 1000);
    };

    const handleLinkedInClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'social_linked_in_click',
            label: window.location.pathname
        });
        setTimeout(() => {
            window.location = 'https://www.linkedin.com/company/dashcarsales';
        }, 1000);
    };

    const handleInstagramClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'social_instagram_in_click',
            label: window.location.pathname
        });
        setTimeout(() => {
            window.location = 'https://www.instagram.com/dashcarsalescom';
        }, 1000);
    };

    React.useEffect(() => {
        if (footerRef.current) {
            document.getElementById('footer').innerHTML = footerRef.current.innerHTML;

            global.handleTwitterClick = handleTwitterClick;
            global.handleLinkedInClick = handleLinkedInClick;
            global.handleInstagramClick = handleInstagramClick;
            global.handleFacebookClick = handleFacebookClick;

            document.getElementById('facebook-link').setAttribute('onclick', 'handleFacebookClick()');
        }
    });

    const cellSize = 4;

    return (
        <div ref={footerRef} style={{display: 'none'}}>
            <div className={classes.root}>
                <div className={classes.wrap}>
                    <Grid className={classes.content} style={{
                        maxWidth: props.is1000 ? (props.screenWidth < 1000 ? '95%' : 1000) : (props.hasColumn ? (props.isItem ? '1280px' : '100%') : props.maxWidth)
                    }}>
                        {props.isMobile ? <div className={classes.socialMobile}><SocialLinks/>
                        </div> : null}
                        <Grid container className={classes.mainGrid}>
                            {!props.isMobile && props.hasColumn ?
                            <Grid item className={classes.left}>

                            </Grid> : null}
                            <Grid item className={classes.other} style={
                                props.hasColumn ? null : {width: '100%'}
                            }>
                                <Grid container spacing={3} className={classes.grid}>
                                    <Grid item xs={6} lg={cellSize} md={cellSize} sm={cellSize}>
                                        <ul>
                                            <li><Typography><b>Company</b></Typography></li>
                                            <li><Typography><a href="/">Home</a></Typography></li>
                                            <li><Typography><a href="/about">About</a></Typography></li>
                                            <li><Typography><a href="/used-cars">Inventory</a></Typography></li>
                                            <li><Typography><a href="/sell-your-car">Sell Your Car</a></Typography></li>
                                            <li><Typography><a href="/contact-us">Contact Us</a></Typography></li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={6} lg={cellSize} md={cellSize} sm={cellSize}>

                                    </Grid>
                                    {!props.isMobile ? <Grid item xs={6} lg={cellSize} md={cellSize} sm={cellSize}>
                                        <div className={classes.social}>
                                            <SocialLinks/>
                                            <ul>
                                                <li><Typography><a href="tel:+15123000914">Call: +1 (512) 3000 914</a></Typography></li>
                                                <li><Typography>Text: +1 (512) 3000 914</Typography></li>
                                                <li><Typography><a href="mailto:contact@dashpreowned.com">contact@dashpreowned.com</a></Typography></li>
                                                <li><Typography>
                                                    1524 W Howard Ln Austin, TX 78728
                                                </Typography></li>
                                            </ul>
                                        </div>
                                    </Grid> : null}
                                    {props.isMobile ? <><Grid item xs={6} lg={6} md={6} sm={6}>
                                        <div className={classes.social}>
                                             <ul>
                                                <li><Typography><a href="tel:+15123000914">Call: +1 (512) 3000 914</a></Typography></li>
                                                <li><Typography>Text: +1 (512) 3000 914</Typography></li>
                                            </ul>
                                        </div>
                                    </Grid>
                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                        <div className={classes.social}>
                                            <ul>
                                                <li><Typography><a href="mailto:contact@dashcarsales.com">Contact@dashpreowned.com</a></Typography></li>
                                                <li><Typography>
                                                    1524 W Howard Ln Austin, TX 78728
                                                </Typography></li>
                                            </ul>
                                        </div>
                                        </Grid>
                                    </> : null}
                                </Grid>
                                <div className={classes.copyright}>
                                    <Typography className={classes.links}>
                                        &copy; {year} Dash Preowned LLC. All rights reserved.
                                        {props.isMobile ? <><br/><br/></> : <>&#160;&#160;</>}
                                        By using this service you are accepting <a className={classes.menuItem} href="/privacy-policy">Privacy policy</a> and <a className={classes.menuItem} href="/terms-and-conditions">Terms and conditions</a>.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}


