import React, { Component } from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import {SocialIcon} from "react-social-icons";

const useStyles = makeStyles((theme) => {
    return {
        root: {
        },
        icon: {
            display: 'inline-block',
            marginRight: 5,
            cursor: 'pointer',
        },
}});

export default function SocialLinks(props) {
    const classes = useStyles();

    const style = {
        width: 35,
        height: 35,
    };

    return (
        <div className={classes.root}>
            <div className={classes.icon} id="facebook-link" aria-label="Facebook" title="Facebook">
                <SocialIcon network="facebook" style={style} bgColor="#fff"/>
            </div>
        </div>
    );
}